<template lang="pug">
section.py-xxl
	.container
		.h1.headline.mb-xl Ventajas del trabajo con nosotros

		.row
			.col-6.col-lg-5
				IconCardBig(
					:icon="require('../assets/images/icon-advantage-1.svg')"
					heading="Amplia geografía"
					text="Entrega en pickpoints y puntos de expedición en más de 100 ciudades de Rusia"
				)

			.col-6.col-lg-5.offset-lg-1
				IconCardBig(
					:icon="require('../assets/images/icon-advantage-2.svg')"
					heading="Fiabilidad"
					text="Garantía de la confirmación del IVA y la seguridad jurídica"
				)
			.w-100.mb-m

			.col-6.col-lg-5
				IconCardBig(
					:icon="require('../assets/images/icon-advantage-3.svg')"
					heading="Transportaciones de cargas internacionales"
					text="Recogida de la carga de cualquier punto del mundo"
				)

			.col-6.col-lg-5.offset-lg-1
				IconCardBig(
					:icon="require('../assets/images/icon-advantage-4.svg')"
					heading="Independencia"
					text="Posibilidad del trabajo con las regiones de la Federación Rusa sin atraer a los distribuidores"
				)
			.w-100.mb-m

			.col-6.col-lg-5
				IconCardBig(
					:icon="require('../assets/images/icon-advantage-5.svg')"
					heading="Soluciones sectoriales"
					text="Productos cosméticos, equipos técnicos, electrodomésticos, bienes de consumo"
				)

			.col-6.col-lg-5.offset-lg-1
				IconCardBig(
					:icon="require('../assets/images/icon-advantage-6.svg')"
					heading="Simplicidad"
					text="Toda la logísitica en el modo de la “ventanilla única”"
				)
</template>

<script>
import IconCardBig from './IconCardBig.vue'

export default {
	components: {
		IconCardBig
	}
}
</script>
