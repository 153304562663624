<template lang="pug">
section.SectionPresentation.pt-xxl
	.container
		.row
			.col-md-6.order-md-last
				.h1.headline.mb-xxs ¿Por qué es útil el sistema de almacenamiento distribuido?
				.headline-offset
					.subline Soluciones de BQB Express
			.w-100.mb-s.d-md-none

			.col-md-6
				Video

	.SectionPresentation-Substrate
</template>

<script>
import Video from './Video.vue'

export default {
	components: {
		Video
	}
}
</script>

<style lang="scss">
@import '../styles/common';

.SectionPresentation {
	background-color: $c-black-squeeze;

	&-Substrate {
		margin-top: -98px;
		height: 130px;
		background-color: $c-licorice;

		@media (min-width: $screen-md) {
			margin-top: -18px;
			height: 50px;
		}

		@media (min-width: $screen-lg) {
			margin-top: -68px;
			height: 100px;
		}

		@media (min-width: $screen-xl) {
			margin-top: -72px;
			height: 112px;
		}
	}
}
</style>
