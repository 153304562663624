<template lang="pug">
.App
	AppHeader
	//- AppMobileMenu
	.AppContent
		router-view
	AppFooter
</template>

<script>
import AppHeader from './components/AppHeader.vue'
// import AppMobileMenu from './components/AppMobileMenu.vue'
import AppFooter from './components/AppFooter.vue'

import './assets/fonts/icons/fonts/icons.svg'

export default {
	name: 'App',
	components: {
		AppHeader,
		// AppMobileMenu,
		AppFooter
	}
}
</script>

<style lang="scss">
@import 'styles/common';

// bootstrap
@import 'styles/custom';
@import '~bootstrap/scss/bootstrap';

// icons
$icomoon-font-path: 'assets/fonts/icons/fonts';
@import 'assets/fonts/icons/style';

html {
	height: 100%;
}

body {
	height: 100%;
	font-size: 14px;

	@media (min-width: $screen-lg) {
		font-size: 12px;
	}
}

.App {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.AppContent {
	flex: 1 0 auto;
}

@import 'styles/elements';
</style>
