<template lang="pug">
section.SectionServices
	.container.py-xxl
		.row
			.col-md-6
				Card(
					:picture="require('../assets/images/bg-service-1.svg')"
					heading="Transportaciones express de cargos por Rusia y por los países de la Comunidad Económica de Eurasia"
					text="BQB Express presta servicios en más de 10 000 destinos de entrega en Rusia y en los países de la Comunidad Económica de Eurasia, teniendo experiencia de la transportación de la mayoría de las categorías de mercancías. Realizamos entregas con nuestro propio transporte en las regiones, como mínimo, en un día. Su cargo se almacenará en la red regional de almacenes de BQB Express hasta el momento de la descarga para el cliente."
				)
			.w-100.mb-xs.d-md-none

			.col-md-6
				Card(
					:picture="require('../assets/images/bg-service-2.svg')"
					heading="Entrega por mensajeros por la Federación Rusa y en la comunicación internacional"
					text="BQB Express es operador de servicios de comunicación postal, licencia No. 181802 del 16.03.20. Realizamos entregas de la correspondencia y envíos por la dirección del cliente, en los puntos de expedición o en pickpoints en más de 100 ciudades de Rusia y en rutas internacionales. Recogeremos el encargo en el transcurso de 4 horas a partir de la tramitación."
				)
			.w-100.mb-xs

			.col-md-6
				Card(
					:picture="require('../assets/images/bg-service-3.svg')"
					heading="Fulfillment"
					subheading="Para tiendas del Internet y empresas mercantiles"
					text="Recogemos la carga al suministrador en cualquier punto de la Federación Rusa y en el extranjero (con su posterior despacho aduanero); desconsolidamos la mercancía y la colocamos en los almacenes propios en las regiones de la Federación Rusa; completamos los pedidos y documentación probatoria, realizamos entregas a su cliente."
				)
			.w-100.mb-xs.d-md-none

			.col-md-6
				Card(
					:picture="require('../assets/images/bg-service-4.svg')"
					heading="Transportaciones express de las cargas en destinos internacionales"
					text="Realizamos transportaciones con nuestras líneas propias de cargas consolidadas de Europa, Asia, Estados Unidos y Australia. Si es necesario, colocamos la mercancía en nuestro almacen en Europa y descargamos por etapas a Rusia. Los especialistas de BQB Express efectúan el despacho aduanero “en tránsito”, sin descargar en depósitos aduaneros."
				)
			.w-100.mb-xs

			.col-md-6
				Card(
					:picture="require('../assets/images/bg-service-5.svg')"
					heading="Almacenamiento distribuido"
					subheading="En las ciudades de la Federación Rusa y en el extranjero"
					text="Distribuimos y almacenamos la mercancía en nuestra propia red de almacenes regionales. Analizando los volúmenes de sus ventas, el sistema BQB recomienda y apoya la cantidad necesaria de su mercancía en la región. De esta manera, disminuimos los gastos para la entrega de una unidad de mercancía y ahorramos tiempo de su comprador."
				)
</template>

<script>
import Card from './Card.vue'

export default {
	components: {
		Card
	}
}
</script>

<style lang="scss">
@import '../styles/common';

.SectionServices {
	background-color: $c-periwinkle;
	background-image: url(../assets/images/getting.svg);
	background-position: center bottom;
	background-size: calc(100% - 30px);
	background-repeat: no-repeat;
	padding-bottom: 66%;

	@media (min-width: $screen-md) {
		background-size: auto;
		padding-bottom: 0;
		background-position: right calc(50% - 172px) bottom;
	}

	@media (min-width: $screen-lg) {
		background-position: right calc(50% - 224px) bottom;
	}

	@media (min-width: $screen-xl) {
		background-position: right calc(50% - 272px) bottom;
	}
}
</style>
