<template lang="pug">
section.SectionMain
	.container.SectionMain-Inner
		img.w-100.SectionMain-Banner(src="../assets/images/delivery-banner_es.jpg" alt="")
		//- .row.no-gutters
			.col-lg-8
				Slider

			.col-lg-4
				Calc

	.SectionMain-Substrate
</template>

<script>
// import Slider from './Slider.vue'
// import Calc from './Calc.vue'

export default {
	components: {
		// Slider,
		// Calc
	}
}
</script>

<style lang="scss">
@import '../styles/common';

.SectionMain {
	position: relative;

	&-Inner {
		@media (max-width: $screen-md - 1) {
			padding: 0;
		}
	}

	&-Banner {
		@include box-shadow-1;

		@media (min-width: $screen-md) {
			border-radius: 0 0 16px 16px;
		}
	}

	&-Substrate {
		height: 130px;
		background-color: $c-periwinkle;
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
	}
}
</style>
