<template lang="pug">
section.SectionWork.py-xxl
	.container
		.row.mb-xl
			.col-md-6
				.h1.headline Trabajamos con personas jurídicas y físicas
			.w-100.mb-xs.d-md-none

			.col-md-6
				.subline Le ofrecemos una solución cómoda para descargas regulares de mercancías en las regiones. Le queda sólo vender la mercancía.

		.row
			.col-md-6.col-lg
				IconCard(
					:icon="require('../assets/images/icon-work-1.svg')"
					text="Licencia para prestar servicios de comunicación postal No. 181802 del 16.03.20"
				)
			.w-100.mb-m.d-md-none

			.col-md-6.col-lg
				IconCard(
					:icon="require('../assets/images/icon-work-2.svg')"
					text="Más de 10 000 direcciones de entrega en Rusia y en los países de la Comunidad Económica de Eurasia"
				)
			.w-100.mb-m.d-lg-none

			.col-md-6.col-lg
				IconCard(
					:icon="require('../assets/images/icon-work-3.svg')"
					text="Recogida urgente de la carga y entrega, como mínimo, en un día"
				)
			.w-100.mb-m.d-md-none

			.col-md-6.col-lg
				IconCard(
					:icon="require('../assets/images/icon-work-4.svg')"
					text="Almacenamiento de la carga con la preparación de la lista de equipos y la entrega a su cliente"
				)
			.w-100.mb-m.d-lg-none

			.col-md-6.col-lg
				IconCard(
					:icon="require('../assets/images/icon-work-5.svg')"
					text="Experiencia de la entrega de la mayoría de categorías de mercancías"
				)
</template>

<script>
import IconCard from './IconCard.vue'

export default {
	components: {
		IconCard
	}
}
</script>

<style lang="scss">
@import '../styles/common';

.SectionWork {
	background-color: $c-black-squeeze;
}
</style>
