<template lang="pug">
#home
	SectionMain
	SectionServices
	SectionAdvantages
	SectionPresentation
	SectionOffer
	SectionShipping
	SectionWork
	SectionRequest
</template>

<script>
import SectionMain from '@/components/SectionMain.vue'
import SectionServices from '@/components/SectionServices.vue'
import SectionAdvantages from '@/components/SectionAdvantages.vue'
import SectionPresentation from '@/components/SectionPresentation.vue'
import SectionOffer from '@/components/SectionOffer.vue'
import SectionShipping from '@/components/SectionShipping.vue'
import SectionWork from '@/components/SectionWork.vue'
import SectionRequest from '@/components/SectionRequest.vue'

export default {
	components: {
		SectionMain,
		SectionServices,
		SectionAdvantages,
		SectionPresentation,
		SectionOffer,
		SectionShipping,
		SectionWork,
		SectionRequest
	}
}
</script>
